//@flow

import React, { useEffect, useRef } from 'react';
import { navigate } from 'gatsby-plugin-intl';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import { PrivateRoute } from '../../services/auth';
import useGlobal from '../../store';

const goTo = async (route, startTime) => {
  const now = new Date().getTime();
  const wait = 1000 - (now - startTime);

  await new Promise(resolve => setTimeout(resolve, wait));

  navigate(route, {
    replace: true,
  });
}

const QuizzPage = () => {
  const startTime = useRef(new Date().getTime());
  const redirected = useRef(false);

  const [globalState, refreshActivity] = useGlobal(
    state => ({ status: state.status, introDone: state.introDone }),
    actions => actions.refreshActivity,
  );

  useEffect(() => {
    console.log('Refresh data from api');
    refreshActivity();
  }, []);

  if (globalState.status !== 'INTIAL' && !redirected.current) {
    redirected.current = true;
    
    if (!globalState.introDone) {
      goTo('/app/intro', startTime.current);
    } else {
      goTo('/app/program', startTime.current);
    }
  }

  return (
    <div style={{ textAlign: 'center', marginTop: 50, marginBottom: 50 }}>
      <Loader type="Oval" color="#FFF" height={46} />
    </div>
  );
};

export default PrivateRoute(QuizzPage);
